<template>
  <div>
    <form class="form flex-column dense-content pricing">
      <div class="row">
        <div
          :class="[
            'position-relative mb-5 mb-lg-0',
            { 'col-lg-3': isSidebarVisible },
            { 'col-lg-auto': !isSidebarVisible },
          ]"
        >
          <div v-if="isSidebarVisible" class="card position-sticky shadow-sm" :style="{ top: '20px' }">
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="fs-4 mb-0 px-4 h-50px d-flex align-items-center kt_items_list_title">Product library</h3>
              <div class="h-50px w-50px d-flex flex-center">
                <px-btn
                  color="active-light"
                  extended-classes="btn-icon btn-icon-muted btn-active-color-primary w-md-40px h-md-40px"
                  @click.native="toggleSidebar"
                >
                  <span class="svg-icon svg-icon-2hx">
                    <inline-svg src="/assets/media/icons/duotune/abstract/abs015.svg" />
                  </span>
                </px-btn>
              </div>
            </div>
            <div class="ghost-change-order-impossible card-body d-flex flex-column pt-0 px-4">
              <div class="d-flex align-items-center my-1">
                <div class="position-relative flex-grow-1">
                  <span class="svg-icon svg-icon-2 position-absolute top-50 start-0 translate-middle-y ms-2">
                    <inline-svg src="/assets/media/icons/duotune/general/gen021.svg" />
                  </span>
                  <input
                    v-model="searchTextComputed"
                    type="text"
                    class="w-100 form-control form-control-solid ps-10"
                    placeholder="Search Items"
                  />
                  <span
                    v-if="searchTextComputed"
                    class="svg-icon svg-icon-2 position-absolute top-50 end-0 translate-middle-y me-2"
                    role="button"
                    @click="searchTextComputed = ''"
                  >
                    <inline-svg src="/assets/media/icons/duotune/arrows/arr088.svg" />
                  </span>
                </div>
                <px-btn color="light-primary" extended-classes="btn-icon px-4 ms-2" @click.native="openModal">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="/assets/media/icons/duotune/arrows/arr075.svg" />
                  </span>
                </px-btn>
              </div>

              <px-draggable-categories
                v-if="pricingTemplateCategories.size"
                :categories="pricingTemplateCategories"
                :scope-type="scopeType"
                :filter-text="searchTextComputed"
                :clone-callback="addNewPricingSectionItem"
                :action-adding-to-last="addNewPricingSectionItemToLastPricingSection"
                :action-edit-template="editPricingTemplate"
                :action-delete-template="deletePricingTemplate"
              />
            </div>
          </div>
          <px-btn
            v-else
            color="light-primary"
            extended-classes="btn-icon position-sticky w-md-40px h-md-40px"
            :style="{ top: '20px' }"
            @click.native="toggleSidebar"
          >
            <span class="svg-icon svg-icon-2hx">
              <inline-svg src="/assets/media/icons/duotune/abstract/abs015.svg" />
            </span>
          </px-btn>
        </div>
        <div :class="[{ 'col-lg-9': isSidebarVisible }, { 'col-lg': !isSidebarVisible }]">
          <div class="card shadow-sm h-100">
            <div
              class="card-header position-sticky top-0 z-index-2 align-items-center justify-content-end bg-light border-0 px-4"
            >
              <div v-if="currentProposal && currentProposal.attributes" class="me-4">
                <px-dropdown-content
                  btn-classes="text-muted cursor-pointer p-2"
                  icon-classes="text-inherit bi bi-table"
                >
                  <div slot="content" class="text-start">
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Item</span>
                        <input
                          v-model="currentProposal.attributes.show_pricing_item"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_pricing_item')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Description</span>
                        <input
                          v-model="currentProposal.attributes.show_pricing_description"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_pricing_description')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Quantity</span>
                        <input
                          v-model="currentProposal.attributes.show_pricing_quantity"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_pricing_quantity')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Price ($)</span>
                        <input
                          v-model="currentProposal.attributes.show_pricing_price"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_pricing_price')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Unit</span>
                        <input
                          v-model="currentProposal.attributes.show_pricing_unit"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_pricing_unit')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Waste (%)</span>
                        <input
                          v-model="currentProposal.attributes.show_pricing_waste"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_pricing_waste')"
                        />
                      </div>
                    </div>
                    <div class="menu-item px-3 my-1">
                      <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                        <span class="text-gray-600">Total</span>
                        <input
                          v-model="currentProposal.attributes.show_pricing_total"
                          class="ms-auto form-check-input h-20px w-30px"
                          type="checkbox"
                          @change="() => onCheckboxChange('show_pricing_total')"
                        />
                      </div>
                    </div>
                  </div>
                </px-dropdown-content>
              </div>
              <div class="d-flex align-items-center me-n2">
                <span class="fs-6 me-2">Project Subtotal:</span>
                <strong v-if="pricingSections.length" class="fs-6 fw-bold ms-auto">
                  {{ $n(subTotalFromItems(pricingSections), 'currency', 'en-CA') }}
                </strong>
              </div>
            </div>
            <div class="card-body px-6 d-flex flex-column pt-5">
              <div v-if="!isLoaded" class="text-center text-primary bg-light-primary rounded p-4">
                <div class="fw-bold mb-1">No items yet... :(</div>
                <div>Create some section...</div>
              </div>
              <div v-else class="ghost-change-order-possible-block">
                <draggable handle=".cursor-grab" :list="pricingSections" @change="sortPricingSectionsByQueue">
                  <px-collapsable-section
                    v-for="section in pricingSections"
                    :key="section.id"
                    :is-opened="section.attributes.is_collapsed"
                    :is-opened-by-dragenter="true"
                    :on-toggle="() => onSectionToggle(section.id, section.attributes.is_collapsed)"
                    @on-dragenter="() => onSectionDragenter(section.id, section.attributes.is_collapsed)"
                  >
                    <span slot="header-left" class="cursor-grab d-inline-flex" role="button">
                      <i class="bi bi-grip-vertical text-gray-400 fs-3" />
                    </span>
                    <div slot="header-right" class="d-flex align-items-center me-n2">
                      <strong class="ms-auto fw-bold">
                        {{ $n(totalFromItems(section.attributes.pricing_items.data), 'currency', 'en-CA') }}
                      </strong>
                      <!--begin::Menu-->
                      <div class="ms-4">
                        <px-dropdown-content
                          btn-classes="text-muted cursor-pointer p-2"
                          icon-classes="text-inherit bi bi-table"
                        >
                          <div slot="content" class="text-start">
                            <div class="menu-item px-3 my-1">
                              <div class="px-3 py-2 form-check form-switch form-check-custom form-check-solid">
                                <span class="text-gray-600">Internal notes</span>
                                <input
                                  v-model="section.attributes.show_notes"
                                  class="ms-auto form-check-input h-20px w-30px"
                                  type="checkbox"
                                />
                              </div>
                            </div>
                          </div>
                        </px-dropdown-content>
                      </div>
                      <!--end::Menu-->
                      <!--begin::Menu-->
                      <div class="ms-4">
                        <px-dropdown
                          :items="
                            section.attributes.show_notes
                              ? [
                                  { title: 'Duplicate', action: () => duplicatePricingSection(section.attributes) },
                                  { title: 'Add Important Notice', action: () => showPricingSectionsNote(section.id) },
                                  { title: 'Delete', action: () => deletePricingSection(section.id) },
                                  {
                                    title: 'Add New Item',
                                    action: () => addNewPricingSectionItemToCurrentPricingSection(section.id),
                                  },
                                ]
                              : [
                                  { title: 'Duplicate', action: () => duplicatePricingSection(section.attributes) },
                                  { title: 'Delete', action: () => deletePricingSection(section.id) },
                                ]
                          "
                        />
                      </div>
                      <!--end::Menu-->
                    </div>
                    <input
                      slot="header-control"
                      v-model="section.attributes.name"
                      class="w-350px form-control form-control-solid fs-6"
                    />
                    <div class="card-body py-0 px-6">
                      <!--begin::Body-->
                      <div class="py-4">
                        <div class="table-responsive">
                          <!--begin::Table-->
                          <table class="ghost-change-order-possible table mb-0">
                            <!--begin::Thead-->
                            <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                              <tr>
                                <th class="position-sticky top-0 start-0 z-index-1 min-w-40px px-0" />
                                <th
                                  v-if="currentProposal.attributes.show_pricing_item"
                                  :class="[
                                    'border-bottom min-w-125px ps-0',
                                    { 'w-125px': currentProposal.attributes.show_pricing_description },
                                  ]"
                                >
                                  Item
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_pricing_description"
                                  class="border-bottom min-w-350px"
                                >
                                  Description
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_pricing_quantity"
                                  :class="[
                                    'border-bottom min-w-125px',
                                    { 'w-125px': currentProposal.attributes.show_pricing_description },
                                  ]"
                                >
                                  Quantity
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_pricing_unit"
                                  :class="[
                                    'border-bottom min-w-100px',
                                    { 'w-100px': currentProposal.attributes.show_pricing_description },
                                  ]"
                                >
                                  Unit
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_pricing_waste"
                                  :class="[
                                    'border-bottom min-w-50px',
                                    { 'w-50px': currentProposal.attributes.show_pricing_description },
                                  ]"
                                >
                                  Waste(%)
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_pricing_price"
                                  :class="[
                                    'border-bottom min-w-100px',
                                    { 'w-100px': currentProposal.attributes.show_pricing_description },
                                  ]"
                                >
                                  Price($)
                                </th>
                                <th
                                  v-if="currentProposal.attributes.show_pricing_total"
                                  :class="[
                                    'border-bottom min-w-50px',
                                    { 'w-50px': section.attributes.show_description },
                                  ]"
                                >
                                  Total
                                </th>
                                <th class="position-sticky top-0 end-0 z-index-1 w-40px border-bottom" />
                              </tr>
                            </thead>
                            <draggable
                              tag="tbody"
                              group="sub-category-items"
                              :list="section.attributes.pricing_items.data"
                              handle=".menu-bullet"
                              @change="sortPricingSectionItemsByQueue"
                            >
                              <tr
                                v-for="item in section.attributes.pricing_items.data"
                                :key="item.id"
                                class="kt-items-list-item additional-control-show-on-hover"
                              >
                                <!--Helpers-->
                                <div class="ghost-content px-4">
                                  <span class="ghost-from-bullet">
                                    <i class="bi bi-grip-vertical fs-3" />
                                  </span>
                                  <span class="ghost-title">
                                    {{ item.attributes.name }}
                                  </span>
                                </div>
                                <td
                                  class="ghost-helper ghost-helper-d-table-cell ghost-helper-space-start"
                                  colspan="8"
                                />
                                <!--/Helpers-->
                                <td class="ghost-invisible position-sticky top-0 start-0 z-index-1 bg-white p-0">
                                  <div
                                    class="additional-control d-inline-flex align-items-center justify-content-center w-100 text-center mt-4"
                                  >
                                    <span class="handle menu-bullet d-inline-flex cursor-grab" role="button">
                                      <i class="bi bi-grip-vertical fs-3" />
                                    </span>
                                  </div>
                                </td>
                                <td v-if="currentProposal.attributes.show_pricing_item" class="ghost-hidden ps-0">
                                  <textarea-autosize
                                    v-model="item.attributes.name"
                                    class="form-control form-control-solid fw-normal"
                                    rows="1"
                                    :min-height="42"
                                    :max-height="500"
                                  />
                                </td>
                                <td v-if="currentProposal.attributes.show_pricing_description" class="ghost-hidden">
                                  <textarea-autosize
                                    v-model="item.attributes.description"
                                    class="form-control form-control-solid fw-normal"
                                    rows="1"
                                    :min-height="42"
                                    :max-height="500"
                                  />
                                  <div v-if="item.attributes.show_client_note" class="position-relative mt-4">
                                    <span
                                      class="p-3 text-hover-danger position-absolute top-0 end-0 mt-n1 me-n9"
                                      role="button"
                                      @click="() => hidePricingSectionItemsNote(section.id, item.id)"
                                    >
                                      <i class="bi bi-x" />
                                    </span>
                                    <label class="d-block position-relative">
                                      <textarea-autosize
                                        v-model="item.attributes.note"
                                        class="form-control form-control-solid fw-normal border-0 pt-8 bg-light-warning"
                                        rows="1"
                                        :min-height="60"
                                        :max-height="500"
                                      />
                                      <span class="label-inside position-absolute top-0 start-0 pb-1 mb-n2">
                                        Client Note
                                      </span>
                                    </label>
                                  </div>
                                  <div
                                    v-if="item.attributes.show_internal_note && section.attributes.show_notes"
                                    class="position-relative mt-4"
                                  >
                                    <span
                                      class="p-3 text-hover-danger position-absolute top-0 end-0 mt-n1 me-n9"
                                      role="button"
                                      @click="() => hidePricingSectionItemsInternalNote(section.id, item.id)"
                                    >
                                      <i class="bi bi-x" />
                                    </span>
                                    <label class="d-block position-relative">
                                      <textarea-autosize
                                        v-model="item.attributes.internal_note"
                                        class="form-control form-control-solid fw-normal border-0 pt-8 bg-light-danger"
                                        rows="1"
                                        :min-height="60"
                                        :max-height="500"
                                      />
                                      <span class="label-inside position-absolute top-0 start-0 pb-1 mb-n2">
                                        Internal Note
                                      </span>
                                    </label>
                                  </div>
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_pricing_quantity"
                                  class="ghost-hidden text-nowrap"
                                >
                                  <PxMoney
                                    v-model="item.attributes.quantity"
                                    :decimal-scale="1"
                                    class="form-control form-control-solid fw-normal"
                                  />
                                  <div class="text-end mt-2">
                                    <span v-if="item.attributes.waste" class="badge badge-primary">
                                      +{{
                                        $n(
                                          Math.ceil((Number(item.attributes.quantity) / 100) * item.attributes.waste),
                                          'quantity',
                                          'en-CA'
                                        )
                                      }}
                                    </span>
                                  </div>
                                  <input
                                    :value="
                                      Number(item.attributes.quantity) +
                                      Number(
                                        Math.ceil((Number(item.attributes.quantity) / 100) * item.attributes.waste)
                                      )
                                    "
                                    v-bind:lazy="
                                      (item.attributes.total_quantity =
                                        Number(item.attributes.quantity) +
                                        Number(
                                          Math.ceil((Number(item.attributes.quantity) / 100) * item.attributes.waste)
                                        ))
                                    "
                                    type="hidden"
                                  />
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_pricing_unit"
                                  class="ghost-hidden text-nowrap"
                                >
                                  <input
                                    v-model="item.attributes.unit"
                                    type="text"
                                    class="form-control form-control-solid fw-normal"
                                  />
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_pricing_waste"
                                  class="ghost-hidden text-nowrap"
                                >
                                  <input
                                    v-model="item.attributes.waste"
                                    v-mask="['#', '##', '###']"
                                    class="form-control form-control-solid fw-normal"
                                    @input="
                                      (evt) => (item.attributes.waste = evt.target.value > 100 ? 100 : evt.target.value)
                                    "
                                  />
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_pricing_price"
                                  class="ghost-hidden text-nowrap"
                                >
                                  <div v-if="item.attributes.price_format === 'Default'">
                                    <PxMoney
                                      v-model="item.attributes.price"
                                      class="form-control form-control-solid fw-normal"
                                    />
                                  </div>
                                </td>
                                <td
                                  v-if="currentProposal.attributes.show_pricing_total"
                                  class="ghost-hidden text-end text-nowrap fw-bold"
                                >
                                  <div
                                    v-if="item.attributes.price_format === 'Default'"
                                    class="d-flex align-items-center h-50px"
                                  >
                                    {{
                                      $n(
                                        (Number(item.attributes.quantity) +
                                          Number(
                                            Math.ceil((Number(item.attributes.quantity) / 100) * item.attributes.waste)
                                          )) *
                                          item.attributes.price,
                                        'currency',
                                        'en-CA'
                                      )
                                    }}
                                  </div>
                                  <select
                                    v-else
                                    v-model="item.attributes.price_format"
                                    class="form-select form-select-solid w-175px"
                                  >
                                    <option
                                      v-for="priceFormat in pricingItemsPriceFormats"
                                      :value="priceFormat"
                                      :key="priceFormat"
                                    >
                                      {{ priceFormat }}
                                    </option>
                                  </select>
                                </td>
                                <td class="ghost-hidden position-sticky top-0 end-0 z-index-1 bg-white text-end pe-0">
                                  <div class="d-inline-flex align-items-center h-50px">
                                    <px-dropdown
                                      :items="
                                        section.attributes.show_notes
                                          ? [
                                              {
                                                title: 'Duplicate',
                                                action: () => duplicatePricingSectionItem(section.id, item.attributes),
                                              },
                                              {
                                                title: 'Change Price Format',
                                                action: () => togglePricingSectionItemsPriceFormat(section.id, item.id),
                                              },
                                              {
                                                title: 'Add Client Note',
                                                action: () => showPricingSectionItemsNote(section.id, item.id),
                                              },
                                              {
                                                title: 'Add Internal Note',
                                                action: () => showPricingSectionItemsInternalNote(section.id, item.id),
                                              },
                                              {
                                                title: 'Delete',
                                                action: () => deletePricingSectionItem(section.id, item.id),
                                              },
                                            ]
                                          : [
                                              {
                                                title: 'Duplicate',
                                                action: () => duplicatePricingSectionItem(section.id, item.attributes),
                                              },
                                              {
                                                title: 'Change Price Format',
                                                action: () => togglePricingSectionItemsPriceFormat(section.id, item.id),
                                              },
                                              {
                                                title: 'Add Client Note',
                                                action: () => showPricingSectionItemsNote(section.id, item.id),
                                              },
                                              {
                                                title: 'Delete',
                                                action: () => deletePricingSectionItem(section.id, item.id),
                                              },
                                            ]
                                      "
                                    />
                                  </div>
                                </td>
                              </tr>
                            </draggable>
                            <tfoot>
                              <tr>
                                <td
                                  class="align-middle fw-bold text-uppercase text-ls fs-9 text-muted text-end"
                                  :colspan="
                                    countTrueQty(
                                      [
                                        currentProposal.attributes.show_pricing_item,
                                        currentProposal.attributes.show_pricing_description,
                                        currentProposal.attributes.show_pricing_quantity,
                                        currentProposal.attributes.show_pricing_unit,
                                        currentProposal.attributes.show_pricing_waste,
                                        currentProposal.attributes.show_pricing_price,
                                      ],
                                      1
                                    )
                                  "
                                >
                                  Total:
                                </td>
                                <td class="align-middle fw-bold">
                                  {{ $n(totalFromItems(section.attributes.pricing_items.data), 'currency', 'en-CA') }}
                                </td>
                                <td class="position-sticky bottom-0 end-0 z-index-1 min-w-40px" />
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>

                      <div v-if="section.attributes.show_important_notice" class="position-relative mb-6 ms-n8 ps-8">
                        <span
                          class="p-3 text-hover-danger position-absolute top-0 end-0 mt-n1 me-n9"
                          role="button"
                          @click="() => hidePricingSectionsNote(section.id)"
                        >
                          <i class="bi bi-x" />
                        </span>
                        <label class="d-block position-relative">
                          <textarea-autosize
                            v-model="section.attributes.note"
                            class="form-control form-control-solid fw-normal border-0 pt-8 bg-light-warning"
                            rows="1"
                            :min-height="60"
                            :max-height="500"
                          />
                          <span class="label-inside position-absolute top-0 start-0 pb-1 mb-n2">Important Notice</span>
                        </label>
                      </div>
                      <!--end::Body-->
                    </div>
                  </px-collapsable-section>
                </draggable>
              </div>

              <div class="text-end mt-4">
                <px-btn
                  size="sm"
                  color="light-primary"
                  extended-classes="add-item-group"
                  @click.native="addNewPricingSection"
                >
                  <i class="bi bi-plus-lg" />
                  New Section
                </px-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="row">
      <div :class="[{ 'col-lg-3': isSidebarVisible }, { 'col-lg-auto': !isSidebarVisible }]" />
      <div :class="[{ 'col-lg-9': isSidebarVisible }, { 'col-lg': !isSidebarVisible }]">
        <!--begin::Actions-->
        <div class="d-flex flex-stack mt-8 pt-8 border-top">
          <!--begin::Wrapper-->
          <div class="me-4">
            <px-btn
              v-if="state !== 'Change Order'"
              color="secondary"
              :in-process="prevInProcess"
              :disabled="prevInProcess"
              @click.native="goToPrev"
            >
              Back
            </px-btn>
          </div>
          <!--end::Wrapper-->
          <!--begin::Wrapper-->
          <div class="ms-auto">
            <px-btn
              color="success"
              extended-classes="me-2"
              :in-process="updateInProcess"
              :disabled="updateInProcess"
              @click.native="onUpdate"
            >
              Save
            </px-btn>
            <px-btn :in-process="nextInProcess" :disabled="nextInProcess" @click.native="goToNext">Next </px-btn>
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Actions-->
      </div>
    </div>

    <!-- new pricing item template begin -->
    <px-new-modal :is-visible="isPricingItemTemplateModalOpened" :close-modal="closeModal">
      <template #title>
        <h3>New Item</h3>
      </template>
      <template #content>
        <pxm-new-pricing-item-template :template-id="editingTemplateId" @onClose="closeModal" />
      </template>
    </px-new-modal>
    <!-- new pricing item template end -->
  </div>
</template>

<script>
import { debounce, cloneDeep } from 'lodash';

import { mapActions, mapGetters, mapMutations } from 'vuex';

import ProposalWizardMixin from '@/mixins/ProposalWizardMixin';

import PxCollapsableSection from '@/views/proposals/wizard/components/PxCollapsableSection';
import PxDraggableCategories from '@/views/proposals/wizard/components/PxDraggableCategories';

import PxmNewPricingItemTemplate from '@/modals/PxmNewPricingItemTemplate';

import draggable from 'vuedraggable';
import { nanoid } from 'nanoid';

export default {
  mixins: [ProposalWizardMixin],
  components: {
    PxCollapsableSection,
    PxDraggableCategories,
    PxmNewPricingItemTemplate,
    draggable,
  },
  data() {
    return {
      searchText: '',
      isSearchInProcess: false,
      isSubmitDisable: false,
      isSidebarVisible: true,
      editingTemplateId: null,
      isPricingItemTemplateModalOpened: false,

      sectionDragenterTimeoutId: null,
    };
  },
  computed: {
    ...mapGetters({
      message: 'proposalWizard/message',
      isLoaded: 'proposalWizard/isLoaded',
      state: 'proposalWizard/state',
      isRenovate: 'proposalWizard/isRenovate',
      // Pricing templates
      pricingItemTemplateMessage: 'pricingItemTemplate/message',
      pricingTemplateCategories: 'proposalWizard/pricingTemplateCategories',
      // Pricing section
      currentProposal: 'proposalWizard/currentProposal',
      pricingSections: 'proposalWizard/pricingSections',
      pricingSectionActiveIndex: 'proposalWizard/pricingSectionActiveIndex',
    }),
    pricingItemsPriceFormats() {
      return ['To be determined', 'Included'];
    },
    searchTextComputed: {
      get() {
        return this.searchText;
      },
      set: debounce(function (newValue) {
        this.searchText = newValue;
      }, 500),
    },
  },
  async mounted() {
    await this.pricingItemTemplateInitCategories();
    await this.setPricingSectionActiveIndex(this.pricingSections.length);
    const price = this.pricingSections.find((el, i) => i + 1 === this.pricingSections.length);
    await this.actionSetPricingSectionIsOpen({
      id: price ? price.id : null,
      is_collapsed: true,
    });
  },
  methods: {
    ...mapActions({
      // Pricing templates
      pricingItemTemplateInitCategories: 'pricingItemTemplate/initCategories',
      pricingItemTemplateArchive: 'pricingItemTemplate/archive',
      actionDeletePricingCategory: 'proposalWizard/deletePricingCategory',
      // Pricing section
      megaUpdate: 'proposalWizard/megaUpdate',
      actionRevertValue: 'proposalWizard/revertValue',
      actionAddNewPricingSection: 'proposalWizard/addNewPricingSection',
      actionDeletePricingSection: 'proposalWizard/deletePricingSection',
      actionSetPricingSectionsNoteVisibility: 'proposalWizard/setPricingSectionsNoteVisibility',
      actionSetPricingSectionIsOpen: 'proposalWizard/setPricingSectionIsOpen',
      actionSortPricingSectionsByQueue: 'proposalWizard/sortPricingSectionsByQueue',
      actionAddNewPricingSectionItem: 'proposalWizard/addNewPricingSectionItem',
      actionDeletePricingSectionItem: 'proposalWizard/deletePricingSectionItem',
      actionTogglePricingSectionItemsPriceFormat: 'proposalWizard/togglePricingSectionItemsPriceFormat',
      actionSetPricingSectionItemsNoteVisibility: 'proposalWizard/setPricingSectionItemsNoteVisibility',
      actionDeletePricingSectionItemsNote: 'proposalWizard/deletePricingSectionItemsNote',
      actionSetPricingSectionItemsInternalNoteVisibility: 'proposalWizard/setPricingSectionItemsInternalNoteVisibility',
      actionDeletePricingSectionItemsInternalNote: 'proposalWizard/deletePricingSectionItemsInternalNote',
      actionSortPricingSectionItemsByQueue: 'proposalWizard/sortPricingSectionItemsByQueue',
    }),
    ...mapMutations({
      setPricingSections: 'proposalWizard/SET_PRICING_SECTIONS',
      setPricingSectionActiveIndex: 'proposalWizard/SET_PRICING_SECTION_ACTIVE_INDEX',
    }),
    // Helpers
    subTotalFromItems(items) {
      let totalPrice = 0;

      if (items.length) {
        items.map((el) => {
          const subTotal = this.totalFromItems(el.attributes.pricing_items.data);
          totalPrice += subTotal;
        });
      }

      return totalPrice;
    },
    totalFromItems(items) {
      let totalPrice = 0;
      items.map((el) => {
        if (el.attributes.price_format === 'Default') {
          const { quantity, waste, price } = el.attributes;
          totalPrice += (quantity + Math.ceil((quantity / 100) * waste)) * price;
        }
      });

      return totalPrice;
    },
    clearString(str) {
      return str.split('(')[0].toLowerCase();
    },
    onCheckboxChange(field) {
      this.actionRevertValue(field);
    },
    // Pricing sections
    onSectionToggle(id, isOpened) {
      this.actionSetPricingSectionIsOpen({
        id,
        is_collapsed: !isOpened,
      });
      this.setPricingSectionActiveIndexAsLastOpenedSectionIndex();
    },
    onSectionDragenter(id, isOpened) {
      if (!isOpened) {
        this.actionSetPricingSectionIsOpen({
          id,
          is_collapsed: true,
        });
        this.setPricingSectionActiveIndexAsLastOpenedSectionIndex();
      }
    },
    setPricingSectionActiveIndexAsLastOpenedSectionIndex() {
      let lastOpenedIndex;

      this.pricingSections.forEach((el, i) => {
        if (el.attributes.is_collapsed) {
          lastOpenedIndex = i + 1;
        }
      });

      if (this.pricingSectionActiveIndex !== lastOpenedIndex && lastOpenedIndex !== undefined) {
        this.setPricingSectionActiveIndex(lastOpenedIndex);
      } else {
        this.setPricingSectionActiveIndex(this.pricingSections.length);
      }
    },
    addNewPricingSection() {
      const newSectionId = nanoid();

      this.actionAddNewPricingSection({
        id: newSectionId,
        type: 'pricing_section',
        attributes: {
          header_settings: ['Item', 'Description', 'Quantity', 'Price($)', 'Unit', 'Waste(%)', 'Total'],
          name: null,
          note: null,
          internal_note: null,
          pricing_items: {
            data: [],
          },
          proposal_scope: {
            data: this.currentProposal,
          },
          queue: this.pricingSections.length,
          is_collapsed: false,
          show_description: true,
          show_item: true,
          show_notes: true,
          show_price: true,
          show_quantity: true,
          show_total: true,
          show_unit: true,
          show_waste: true,
          show_important_notice: false,
        },
      });
      this.setPricingSections(this.pricingSections);
      this.onSectionToggle(newSectionId, false);
    },
    duplicatePricingSection(attributes) {
      const newAttributes = cloneDeep(attributes);

      if (!newAttributes.pricing_items?.data?.length) {
        this.v_toast(301, `Pricing section is empty. Please add some items for duplication.`, () => {}, {
          timer: 5000,
        });

        return false;
      }

      this.actionAddNewPricingSection({
        id: nanoid(),
        type: 'pricing_section',
        attributes: newAttributes,
      });
      this.setPricingSections(this.pricingSections);
      this.setPricingSectionActiveIndex(this.pricingSections.length);
      this.setPricingSectionActiveIndexAsLastOpenedSectionIndex();
    },
    deletePricingSection(id) {
      this.actionDeletePricingSection(id);
      this.setPricingSections(this.pricingSections);
      this.setPricingSectionActiveIndex(this.pricingSections.length);
      this.setPricingSectionActiveIndexAsLastOpenedSectionIndex();
    },
    showPricingSectionsNote(id) {
      this.actionSetPricingSectionsNoteVisibility({ id: id, state: true });
      this.setPricingSections(this.pricingSections);
    },
    hidePricingSectionsNote(id) {
      this.actionSetPricingSectionsNoteVisibility({ id: id, state: false });
      this.setPricingSections(this.pricingSections);
    },
    sortPricingSectionsByQueue() {
      this.actionSortPricingSectionsByQueue();
      this.setPricingSections(this.pricingSections);
    },
    // Pricing section items
    addNewPricingSectionItem(item) {
      const newAttributes = cloneDeep(item.attributes);
      const newItem = {
        id: nanoid(),
        type: 'pricing_item',
        attributes: {
          name: newAttributes.name,
          pricing_section: null,
          description: newAttributes.description,
          unit: newAttributes.unit,
          waste: newAttributes.waste,
          price: newAttributes.price,
          note: newAttributes.note,
          internal_note: newAttributes.internal_note,
          quantity: 1,
          price_format: 'Default',
          show_client_note: newAttributes.show_client_note,
          show_internal_note: newAttributes.show_internal_note,
        },
      };

      if (item.attributes) {
        newItem.pricing_item_template = {
          data: { ...item },
        };
      }

      return newItem;
    },
    addNewPricingSectionItemToLastPricingSection(item) {
      const newAttributes = cloneDeep(item.attributes);

      if (!this.pricingSections.filter((el) => el.attributes.is_collapsed).length) {
        this.actionSetPricingSectionIsOpen({
          id: this.pricingSections[this.pricingSectionActiveIndex - 1].id,
          is_collapsed: true,
        });
      }

      this.duplicatePricingSectionItem(this.pricingSections[this.pricingSectionActiveIndex - 1].id, {
        name: newAttributes.name,
        queue: this.pricingSections[this.pricingSectionActiveIndex - 1].attributes.pricing_items.data.length,
        pricing_section: null,
        description: newAttributes.description,
        unit: newAttributes.unit,
        waste: newAttributes.waste,
        price: newAttributes.price,
        note: newAttributes.note,
        internal_note: newAttributes.internal_note,
        quantity: 1,
        pricing_item_template: {
          data: { ...item },
        },
        price_format: 'Default',
        show_client_note: newAttributes.show_client_note,
        show_internal_note: newAttributes.show_internal_note,
      });
      this.setPricingSections(this.pricingSections);
    },
    addNewPricingSectionItemToCurrentPricingSection(sectionId) {
      this.duplicatePricingSectionItem(sectionId, {
        name: '',
        queue: this.pricingSections.find((el) => el.id === sectionId).attributes.pricing_items.data.length,
        pricing_section: null,
        description: '',
        unit: 'each',
        waste: null,
        price: 0,
        note: null,
        internal_note: null,
        quantity: 1,
        price_format: 'Default',
        show_client_note: null,
        show_internal_note: null,
        // created_at: null,
        // updated_at: null,
        // category: null,
        // subcategory: null,
        // cost: 0,
        // tags: null,
        // min_quantity: null,
        // recommend_adding: null,
      });
      this.setPricingSections(this.pricingSections);
    },
    async editPricingTemplate(data) {
      this.editingTemplateId = data.id;

      this.isPricingItemTemplateModalOpened = true;
    },
    async deletePricingTemplate(item) {
      await this.pricingItemTemplateArchive(item.id);
      await this.actionDeletePricingCategory(item);
      await this.v_toast(this.pricingItemTemplateMessage?.status, 'Deleted successfully!');
    },
    duplicatePricingSectionItem(sectionId, attributes) {
      const newAttributes = cloneDeep(attributes);

      this.actionAddNewPricingSectionItem({
        sectionId: sectionId,
        item: {
          id: nanoid(),
          type: 'pricing_item',
          attributes: newAttributes,
        },
      });
      this.setPricingSections(this.pricingSections);
    },
    deletePricingSectionItem(sectionId, id) {
      this.actionDeletePricingSectionItem({ sectionId: sectionId, id: id });
      this.setPricingSections(this.pricingSections);
    },
    togglePricingSectionItemsPriceFormat(sectionId, id) {
      this.actionTogglePricingSectionItemsPriceFormat({ sectionId: sectionId, id: id });
      this.setPricingSections(this.pricingSections);
    },
    showPricingSectionItemsNote(sectionId, id) {
      this.actionSetPricingSectionItemsNoteVisibility({ sectionId: sectionId, id: id, state: true });
      this.setPricingSections(this.pricingSections);
    },
    hidePricingSectionItemsNote(sectionId, id) {
      this.actionSetPricingSectionItemsNoteVisibility({ sectionId: sectionId, id: id, state: false });
      this.actionDeletePricingSectionItemsNote({ sectionId: sectionId, id: id, state: '' });
      this.setPricingSections(this.pricingSections);
    },
    showPricingSectionItemsInternalNote(sectionId, id) {
      this.actionSetPricingSectionItemsInternalNoteVisibility({ sectionId: sectionId, id: id, state: true });
      this.setPricingSections(this.pricingSections);
    },
    hidePricingSectionItemsInternalNote(sectionId, id) {
      this.actionSetPricingSectionItemsInternalNoteVisibility({ sectionId: sectionId, id: id, state: false });
      this.actionDeletePricingSectionItemsInternalNote({ sectionId: sectionId, id: id, state: '' });
      this.setPricingSections(this.pricingSections);
    },
    sortPricingSectionItemsByQueue() {
      this.actionSortPricingSectionItemsByQueue();
      this.setPricingSections(this.pricingSections);
    },
    // General
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    openModal() {
      this.isPricingItemTemplateModalOpened = true;
    },
    closeModal(data) {
      this.editingTemplateId = null;
      this.isPricingItemTemplateModalOpened = false;

      if (data) {
        this.addNewPricingSectionItemToLastPricingSection(data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.kt-items-list-item {
  .add-item-btn {
    opacity: 0;
  }

  &:hover {
    .add-item-btn {
      opacity: 1;
    }
  }
}
</style>
