import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      form: {
        name: '',
      },
    };
  },
  validations: {
    form: {
      name: { required },
    },
  },
  methods: {
    defaultValues() {
      this.form = {
        name: '',
      };
    },
  },
};
