<template>
  <div>
    <form class="form" novalidate="novalidate">
      <div class="row">
        <div class="col-md-6 mb-8">
          <!--begin::Label-->
          <label class="form-label fw-semi-bold required">Name</label>
          <!--end::Label-->
          <!--begin::Input-->
          <input
            v-model="form.name"
            :class="['form-control form-control-solid', validateModel($v.form.name.$error)]"
            type="text"
            placeholder="Enter Item Name"
          />
          <!--end::Input-->
        </div>
        <div class="col-md-6 mb-8">
          <!--begin::Label-->
          <label class="form-label fw-semi-bold">Unit Price</label>
          <!--end::Label-->
          <!--begin::Input-->
          <PxMoney v-model="form.price" class="form-control form-control-solid" />
          <!--end::Input-->
        </div>
      </div>
      <!--begin::Input group-->
      <div class="mb-8">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">SKU </label>
        <!--end::Label-->
        <!--begin::Input-->
        <input
          v-model="form.sku"
          :class="['form-control form-control-solid', validateModel($v.form.sku.$error)]"
          type="text"
          placeholder="Enter Item SKU"
        />
        <!--end::Input-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="mb-8">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">Description</label>
        <!--end::Label-->
        <!--begin::Input-->
        <textarea-autosize
          v-model="form.description"
          class="form-control form-control-solid"
          placeholder="Type Item Description"
          rows="1"
          :min-height="42"
          :max-height="500"
        />
        <!--end::Input-->
      </div>
      <!--end::Input group-->
      <div class="row">
        <div class="col-md-6 mb-8">
          <!--begin::Label-->
          <label class="form-label fw-semi-bold">Unit</label>
          <!--end::Label-->
          <!--begin::Input-->
          <div v-if="units && units.data && units.data.length" class="row gx-2">
            <div class="col">
              <px-select
                :value="form.unit"
                :clear="false"
                placeholder="Select unit"
                :items="units.data"
                track-by="attributes.name"
                text-by="attributes.name"
                :minimum-results-for-search="Infinity"
                select2-id="select2_unit"
                @input="(val) => (form.unit = val)"
              />
            </div>
            <div class="col-auto">
              <px-btn extended-classes="btn-icon btn-light-primary px-4" @click.native="openAddUnitModal">
                <span class="svg-icon svg-icon-2x">
                  <inline-svg src="/assets/media/icons/duotune/arrows/arr075.svg" />
                </span>
              </px-btn>
            </div>
          </div>
          <!--end::Input-->
        </div>
        <div class="col-md-6 mb-8">
          <!--begin::Label-->
          <label class="form-label fw-semi-bold">Waste</label>
          <!--end::Label-->
          <div class="input-group input-group-solid mb-0">
            <money
              v-model="form.waste"
              v-bind="quantity"
              type="text"
              class="form-control"
              placeholder="Enter Waste Percentage"
            />
            <span class="input-group-text text-muted border-0">%</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-8">
          <!--begin::Label-->
          <label class="form-label fw-semi-bold required">Category</label>
          <!--end::Label-->
          <!--begin::Input-->
          <px-select
            v-model="form.category"
            classes="form-select form-select-solid"
            :state="$v.form.category.$error"
            :clear="false"
            placeholder="Select category"
            :items="possibleCategories"
            :minimum-results-for-search="Infinity"
            track-by="title"
            text-by="title"
            select2-id="select2_category"
            @input="onChangeCategory"
          />
          <!--end::Input-->
        </div>
        <div class="col-md-6 mb-8">
          <!--begin::Label-->
          <label class="form-label fw-semi-bold required">Sub Category</label>
          <!--end::Label-->
          <!--begin::Input-->
          <px-select
            v-model="form.subcategory"
            classes="form-select form-select-solid"
            :state="$v.form.subcategory.$error"
            :clear="false"
            placeholder="Select sub category"
            :items="possibleSubCategories"
            :minimum-results-for-search="Infinity"
            track-by="title"
            text-by="title"
            select2-id="select2_sub_category"
          />
          <!--end::Input-->
        </div>
      </div>
      <!--begin::Input group-->
      <div class="mb-8">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">Client note</label>
        <!--end::Label-->
        <!--begin::Input-->
        <textarea-autosize
          v-model="form.note"
          class="form-control form-control-solid bg-light-warning border-0"
          placeholder="Type Client Note"
          rows="1"
          :min-height="42"
          :max-height="500"
        />
        <!--end::Input-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="mb-10">
        <!--begin::Label-->
        <label class="form-label fw-semi-bold">Internal note</label>
        <!--end::Label-->
        <!--begin::Input-->
        <textarea-autosize
          v-model="form.internal_note"
          class="form-control form-control-solid bg-light-danger border-0"
          placeholder="Type Internal Note"
          rows="1"
          :min-height="42"
          :max-height="500"
        />
        <!--end::Input-->
      </div>
      <!--end::Input group-->
      <!--begin::Actions-->
      <div class="d-flex flex-stack">
        <px-btn color="light" extended-classes="btn-active-light-primary me-4" @click.native="close">Cancel</px-btn>
        <div class="d-flex align-items-center justify-content-end">
          <px-btn
            v-if="!templateId"
            color="light-primary"
            extended-classes="me-4"
            :in-process="createAndAddToBuilderInProcess"
            :disabled="createAndAddToBuilderInProcess || updateInProcess || createInProcess"
            @click.native="() => onSubmit(true)"
          >
            Add to Library & Proposal
          </px-btn>
          <px-btn
            v-if="templateId"
            :in-process="updateInProcess"
            :disabled="updateInProcess || createAndAddToBuilderInProcess"
            @click.native="onUpdate"
          >
            Update
          </px-btn>
          <px-btn
            v-else
            :in-process="createInProcess"
            :disabled="createInProcess || createAndAddToBuilderInProcess"
            @click.native="() => onSubmit(false)"
          >
            Create
          </px-btn>
        </div>
      </div>
      <!--end::Actions-->
    </form>

    <px-new-modal :is-visible="isAddUnitModalOpened" modal-size="mw-500px" :close-modal="closeAddUnitModal">
      <template #title>
        <h3>New Unit Item</h3>
      </template>
      <template #content>
        <pxm-add-new-unit @unit-added="onUnitAdd" @onClose="closeAddUnitModal" />
      </template>
    </px-new-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import PricingItemTemplateMixin from '@/mixins/PricingItemTemplateMixin';

import PxmAddNewUnit from '@/modals/PxmAddNewUnit';

export default {
  data() {
    return {
      isAddUnitModalOpened: false,

      createAndAddToBuilderInProcess: false,
      updateInProcess: false,
      createInProcess: false,
    };
  },
  mixins: [PricingItemTemplateMixin],
  components: { PxmAddNewUnit },
  computed: {
    ...mapGetters({
      message: 'pricingItemTemplate/message',
      units: 'units/units',
    }),
  },
  methods: {
    ...mapActions({
      create: 'pricingItemTemplate/create',
      update: 'pricingItemTemplate/update',
      actionAddNewPricingCategory: 'proposalWizard/addNewPricingCategory',
      actionUpdatePricingCategory: 'proposalWizard/updatePricingCategory',
    }),
    ...mapMutations({
      setUnits: 'units/SET_UNITS',
    }),
    setCategories() {
      const newPossibleCategories = [];
      let counter = 1;
      for (let category of this.categories.keys()) {
        newPossibleCategories.push({
          id: `100${counter}`,
          title: category,
        });
        counter++;
      }
      this.possibleCategories = newPossibleCategories;
    },
    setSubCategories() {
      const newPossibleSubCategories = [];
      let counter = 1;
      const currentCategory = this.categories.get(this.form.category);
      for (let subCategory of currentCategory.keys()) {
        newPossibleSubCategories.push({
          id: `100${counter}`,
          title: subCategory,
        });
        counter++;
      }
      this.possibleSubCategories = newPossibleSubCategories;
    },
    async initForm(data = null) {
      await this.v_toast(this.message?.status, 'Created successfully!');
      await this.$v.form.$reset();
      this.defaultValues();
      this.$emit('onClose', data);
    },
    onChangeCategory() {
      this.possibleSubCategories = [];
      this.form.subcategory = '';
      this.setSubCategories();
    },
    onUnitAdd(data) {
      this.setUnits({
        ...this.units,
        data: [data, ...this.units.data],
      });

      this.form.unit = data.id;
    },
    close() {
      this.defaultValues();
      this.$emit('onClose', null);
    },
    openAddUnitModal() {
      this.isAddUnitModalOpened = true;
    },
    closeAddUnitModal() {
      this.isAddUnitModalOpened = false;
    },
    async onSubmit(isShouldAdded = false) {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return false;
      }

      if (isShouldAdded) {
        this.createAndAddToBuilderInProcess = true;
      } else {
        this.createInProcess = true;
      }

      let data = this.form;
      const newPricingTemplate = await this.create(data);

      await this.actionAddNewPricingCategory(newPricingTemplate.data.data);
      await this.initForm(isShouldAdded ? newPricingTemplate.data.data : null);

      if (isShouldAdded) {
        this.createAndAddToBuilderInProcess = false;
      } else {
        this.createInProcess = false;
      }
    },
    async onUpdate() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return false;
      }

      this.updateInProcess = true;

      let data = {
        id: this.templateId,
        attributes: this.form,
      };

      await this.update(data);
      await this.actionUpdatePricingCategory(data);
      await this.initForm();

      this.updateInProcess = false;
    },
  },
};
</script>
