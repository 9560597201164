import { mapActions, mapGetters } from 'vuex';

import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    templateId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: '',
        price: '',
        sku: '',
        description: '',
        unit: '',
        waste: '',
        category: '',
        subcategory: '',
        note: '',
        internal_note: '',
      },
      showModal: false,
      possibleCategories: [],
      possibleSubCategories: [],
    };
  },
  validations: {
    form: {
      name: { required },
      category: { required },
      subcategory: { required },
      sku: { required },
    },
  },
  computed: {
    ...mapGetters({
      categories: 'pricingItemTemplate/categories',
    }),
  },
  async mounted() {
    if (this.templateId) {
      const item = await this.actionPricingItemTemplateSingle(this.templateId);
      const itemData = await item.data.data.attributes;

      this.form = {
        name: itemData.name || '',
        price: itemData.price || '',
        description: itemData.description || '',
        sku: itemData.sku || '',
        unit: itemData.unit || '',
        waste: itemData.waste || '',
        category: itemData.category || '',
        subcategory: itemData.subcategory || '',
        note: itemData.note || '',
        internal_note: itemData.internal_note || '',
      };
    }

    this.setCategories();

    if (this.form.subcategory) {
      this.setSubCategories();
    }
  },
  methods: {
    ...mapActions({
      actionPricingItemTemplateSingle: 'pricingItemTemplate/single',
    }),
    defaultValues() {
      this.form = {
        name: '',
        price: '',
        sku: '',
        description: '',
        unit: '',
        waste: '',
        category: '',
        subcategory: '',
        note: '',
        internal_note: '',
      };
    },
  },
};
